import styles from "./MerchantTabs.module.scss";
import React, { useEffect, useState } from "react";
import { Grid, LandingPagePreview } from "common";
import MerchantInformationCard from "./MerchantInformationCard";
import { MerchantTabType } from "common/types";
import { useLoadingBar } from "hooks/useLoadingBar";
import useMerchant from "hooks/useMerchant";
import Observer, { EVENTS } from "classes/Observer";
import { useNavigate, useParams } from "react-router-dom";
import { useBusinessModel } from "hooks/useBusinessModel";
import DTMLandingPageComingSoon from "./settings/merchant/content/DTMLandingPageComingSoon";
import { useFlag } from "hooks/useFlag";

interface IMerchantExperienceSettingsTab {
  variant: MerchantTabType;
}

const MerchantExperienceSettingsTab: React.FC<IMerchantExperienceSettingsTab> = ({ variant }) => {
  const merchant = useMerchant();
  useLoadingBar(merchant);
  const navigate = useNavigate();
  const merchantUpdated = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const [originalSlug, setOriginalSlug] = useState<string>(null);
  const params = useParams();
  const { merchantSlug } = useParams();
  const selectedBusinessModel = useBusinessModel();
  const enableDtmDtcToggle = useFlag("dtm-dtc-toggle");

  useEffect(() => {
    if (merchantSlug) {
      setOriginalSlug(merchantSlug);
    }
  }, [params]);

  useEffect(() => {
    if (window.location.pathname.includes("viewMerchant") && originalSlug !== merchant?.slug && merchant?.slug) {
      setOriginalSlug(merchant?.slug);
      navigate("/viewMerchant/" + merchant?.slug + "/experienceSettings");
    }
  }, [merchantUpdated]);

  return <>
    <MerchantInformationCard variant={variant} />
    {merchant && <div className={styles.contentContainer}>
      <Grid>
        <Grid item>
          <h2>{merchant?.name} Landing Page Preview</h2>
        </Grid>
        <Grid item>
          <LandingPagePreview
            hero={merchant?.industry_template?.hero_image || "/assets/images/merchantLandingBanner.png"}
            subtitle={merchant?.industry_template?.body || null}
            title={merchant?.industry_template?.title || null}
            {...merchant?.color_theme && { colors: [merchant?.color_theme?.primary_light, merchant?.color_theme?.primary_dark, merchant?.color_theme?.secondary] }}
            {...merchant?.primary_logo && { preview_logo: merchant?.primary_logo }}
            forceMerchant={true}
            showOnlyAboveFold={false}
          />
        </Grid>
      </Grid>
    </div>}
  </>;
};

export default MerchantExperienceSettingsTab;

