import React, { useEffect, useState } from "react";
import styles from "./ApplicationProgressCard.module.scss";
import { AppIcon, Breadcrumbs, Button } from "common";
import { CardTick, ReceiptEdit, Mouse, UsdCoin, PlayCircle } from "iconsax-react";
import { useParams } from "react-router-dom";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import _ from "lodash";
import { formatAPIDateFullYear, formatAmount2, formatFullname } from "utils/formatters";
import appActions from "reducers/AppReducer";
import { isAmountValid, isStringPresent } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { useFlag } from "hooks/useFlag";
import { useAuth } from "auth/useAuth";

const ApplicationProgeressCard: React.FC = () => {
  const { applicantId } = useParams();
  const dispatch = useAppDispatch();
  const applicationOffers = useAppSelector(state => state.borrower.applicationOffers);
  const [details, setDetails] = useState(null);
  const [fundedOffers, setFundedOffers] = useState<Array<any>>([]);
  const [refunds, setRefunds] = useState<any>();
  const enableAdjustmentsAndRefunds = useFlag("adjustment-refunds");
  const user = useAuth()?.user;

  useEffect(() => {
    if (details?.id && applicationEligibleForRefund()) {
      LoanApplicationHandler.getRefunds("", details?.id)
        .then(response => {
          setRefunds(response);
        });
    }
  }, [details, user?.user_type]);

  useEffect(() => {
    return () => {
      dispatch(appActions.clearBreadcrumbs());
    }
  }, []);

  useEffect(() => {
    if (applicantId) {
      getApplication();
    }
  }, [applicantId]);

  const getApplication = () => {
    LoanApplicationHandler.get(applicantId)
      .then(response => {
        setDetails(response);
        if (window.location.href.includes("bc=1")) {
          dispatch(appActions.setBreadcrumbs([
            { title: `${response?.merchant?.name}`, url: `/viewMerchant/${response?.merchant?.slug}/applications` }
          ]));
        }
      });
  }

  useEffect(() => {
    const fundedOffersTemp = [];
    _.forEach(applicationOffers, offer => {
      if (offer?.status === "Funded") {
        fundedOffersTemp.push(offer);
      }
    })
    setFundedOffers(fundedOffersTemp);
  }, [applicationOffers]);

  const iconSize = 25;

  const getFundedOffersLenders = (): string => {
    let fundedOffersLenders = "";
    if (fundedOffers.length > 1) {
      fundedOffers.forEach((fundedOffer) => {
        fundedOffersLenders += fundedOffer.lender_name + ", ";
      });
      fundedOffersLenders = fundedOffersLenders.replace(/,\s*$/, "");
      return fundedOffersLenders;
    }
  }

  const applicationFunded = isAmountValid(details?.funded_amount);
  const progress = [
    isStringPresent(details?.created_at), isAmountValid(details?.loan_amount),
    isStringPresent(details?.offered_at), isStringPresent(details?.clicked_at),
    isAmountValid(details?.funded_amount)
  ];

  const handleHandleRequestRefund = (applicationDetails: any) => {
    dispatch(popUpActions.openPopup({ name: POPUPS.REQUEST_REFUND, message: applicationDetails }));
  }

  // this does not take into account if the application already have a refund in process
  const applicationEligibleForRefund = () => {
    return enableAdjustmentsAndRefunds && user?.user_type === "MERCHANT" && details?.status === "Funded" && details?.lender_type === "DTM";
  }

  const refundInProcess = () => {
    if (refunds?.results?.length > 0) {
      return refunds?.results.some(refund => refund.status === "REQUESTED" || refund.status === "PENDING");
    }
  }

  return <>
    <div>
      {details && <>
        <div className={styles.breadcrumbsRefundWrapper}>
          <Breadcrumbs pageTitle={formatFullname(details?.first_name, details?.last_name)} />
          {applicationEligibleForRefund() && refunds && <Button id="request-refund-btn" label={refundInProcess() ? "Refund in process" : "Request refund"} disabled={refundInProcess() ? true : false} onClick={() => handleHandleRequestRefund(details)} />}
        </div>
      </>}
      {applicationFunded && fundedOffers.length > 0 && <div className={styles.cardHeader}>
        <div className={styles.headerWrapper}>
          {fundedOffers.length === 1 && <>
            <p>{formatAmount2(fundedOffers[0].funded_amount)} offer with {fundedOffers[0].lender_name}</p>
            <p className={styles.offerDetailsWrapper}>
              <span className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].monthlyPayment}</span>
              <span className={styles.offerDetails}>/{(fundedOffers[0].term_unit as string).toLowerCase()} for </span>
              <span className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].term}</span>
              <span className={styles.offerDetails}> {(fundedOffers[0].term_unit as string).toLowerCase()}s | </span>
              <span className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].apr_num}%</span>
              <span className={styles.offerDetails}> APR</span>
            </p>
          </>
          }
          {(fundedOffers.length > 1) && <>
            <p>{formatAmount2(details?.funded_amount)} with multiple offers</p>
            <p>{getFundedOffersLenders()}</p>
          </>
          }
        </div>
      </div>
      }
      <div className={applicationFunded ? styles.card : styles.card + " " + styles.withoutHeader}>
        <div className={styles.columns}>
          <div className={styles.line} />
          <div className={styles.stage}>
            <div className={styles.icon_wrapper}>
              <AppIcon color="var(--primaryTextColor)" size={iconSize} bigCircle={true} icon={PlayCircle} circleBackground={true} circleColor={progress[0] ? "var(--primaryColor)" : "var(--primaryColor20Solid)"} reverseColors={progress[0]} />
            </div>
            <p className={styles.status}>Started</p>
            <p className={styles.date}>{formatAPIDateFullYear(details?.created_at)}</p>
          </div>
          <div className={styles.stage}>
            <div className={styles.icon_wrapper}>
              <AppIcon color="var(--primaryTextColor)" size={iconSize} bigCircle={true} icon={ReceiptEdit} circleBackground={true} circleColor={progress[1] ? "var(--primaryColor)" : "var(--primaryColor20Solid)"} reverseColors={progress[1]} />
            </div>
            <p className={styles.status}>Requested</p>
            <p className={styles.date}>{formatAPIDateFullYear(details?.submitted_at)}</p>
            <p className={styles.loan_amount}>{formatAmount2(details?.loan_amount)}</p>
          </div>
          <div className={styles.stage}>
            <div className={styles.icon_wrapper}>
              <AppIcon color="var(--primaryTextColor)" size={iconSize} bigCircle={true} icon={UsdCoin} circleBackground={true} circleColor={progress[2] ? "var(--primaryColor)" : "var(--primaryColor20Solid)"} reverseColors={progress[2]} />
            </div>
            <p className={styles.status}>Offered</p>
            <p className={styles.date}>{formatAPIDateFullYear(details?.offered_at)}</p>
            <p className={styles.loan_amount}></p>
          </div>
          <div className={styles.stage}>
            <div className={styles.icon_wrapper}>
              <AppIcon color="var(--primaryTextColor)" size={iconSize} bigCircle={true} icon={Mouse} circleBackground={true} circleColor={progress[3] ? "var(--primaryColor)" : "var(--primaryColor20Solid)"} reverseColors={progress[3]} />
            </div>
            <p className={styles.status}>Clicked</p>
            <p className={styles.date}>{formatAPIDateFullYear(details?.clicked_at)}</p>
            <p className={styles.loan_amount}></p>
          </div>
          <div className={styles.stage}>
            <div className={styles.icon_wrapper}>
              <AppIcon color="var(--primaryTextColor)" size={iconSize} bigCircle={true} icon={CardTick} circleBackground={true} circleColor={progress[4] ? "var(--primaryColor)" : "var(--primaryColor20Solid)"} reverseColors={progress[4]} />
            </div>
            <p className={styles.status}>Funded</p>
            <p className={styles.date}>{formatAPIDateFullYear(details?.funded_at)}</p>
            <p className={styles.loan_amount}>{formatAmount2(details?.funded_amount)}</p>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default ApplicationProgeressCard;